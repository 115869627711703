import classNames from 'classnames'
import { ControlPosition, icon, LatLng, LatLngExpression } from 'leaflet'
import React, { ReactNode, useEffect, useId } from 'react'
import { MapContainer, MapContainerProps, Marker, TileLayer, useMap, ZoomControl } from 'react-leaflet'
import mapMarkerIcon from '../../assets/MapMarkerIcon.svg'

export interface DragEndEvent {
  target: { getLatLng: () => LatLng | undefined; setLatLng: (value: LatLng) => void }
}
export interface LeafletMapProps extends MapContainerProps {
  children?: ReactNode
  zoomControlPosition?: ControlPosition
  invalidateMapCacheCondition?: boolean
  id?: string
  showMarkerOnCenter?: boolean
  interactive?: boolean
  onDragEnd?: (event: DragEndEvent) => void
}

export const LeafletMap = (props: LeafletMapProps) => {
  const id = useId()

  return (
    <MapContainer
      {...props}
      zoom={props.zoom ?? 12}
      zoomControl={false}
      className={classNames('z-0 h-full w-full', props.className)}
      id={props.id ?? id}
    >
      <TileLayer
        attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControl position={props.zoomControlPosition ?? 'topleft'} />
      {props.showMarkerOnCenter && props.center && (
        <>
          <Marker
            icon={icon({ iconUrl: mapMarkerIcon, iconSize: [26, 35], iconAnchor: [13, 35] })}
            draggable={props.interactive}
            position={props.center}
            eventHandlers={{ dragend: props.onDragEnd }}
          />
          <MoveCenterHandler center={props.center} />
        </>
      )}
      {props.invalidateMapCacheCondition && <MapHandler condition={props.invalidateMapCacheCondition} />}
      {props.children}
    </MapContainer>
  )
}
const MapHandler = ({ condition }: { condition: boolean }) => {
  const map = useMap()
  useEffect(() => {
    map.invalidateSize()
  }, [condition])

  return null
}

const MoveCenterHandler = ({ center }: { center?: LatLngExpression | undefined }) => {
  const map = useMap()
  useEffect(() => {
    if (center) {
      map.panTo(center)
    }
  }, [center])
  return null
}
